<template>
  <section class="main-section lnb-tree-section">
    <main-section-header
      title="체크시트"
      :is-sticky="true"
      :is-smaller="true"
      :selected-item-name="selectedEqpNameCode"
    >
      <template v-if="checkSheet">
        <button-basic
          v-if="!checkSheetItems || checkSheetItems.length === 0"
          slot="button"
          color="primary"
          size="s"
          @click="openRegisterPage"
        >
          <plus-icon :size="16" />
          등록
        </button-basic>
        <template v-else>
          <button-basic slot="button" color="gray-border-1" size="s" :width="120" @click="togglePopup('CopyPopup')">
            체크시트 복제
          </button-basic>
          <button-basic slot="button" color="primary" size="s" @click="openEditPage">수정</button-basic>
        </template>
      </template>
    </main-section-header>
    <div class="container">
      <div v-if="!selectedEqpId" class="no-data-description">
        <loading-bar v-if="hasEqpByGroup === null" />
        <none-eqp-desc item-name="체크시트" v-else-if="hasEqpByGroup === false" />
        <p v-else-if="hasEqpByGroup === true">좌측에서 설비를 선택하면, 등록된 체크시트를 확인할 수 있습니다.</p>
      </div>
      <check-sheet-items
        v-else
        :is-draggable="false"
        :is-possible-hover-list="false"
        :check-sheet-items="checkSheetItems"
        :is-edit-mode="false"
      />
    </div>
    <copy-popup
      :is-show="showedPopupName === 'CopyPopup'"
      title="check_sheet_copy_title"
      description="check_sheet_copy_description"
      button="check_sheet_copy_button"
      @close="togglePopup"
      @confirm="onCopyCheckSheet"
    />
    <confirm-popup
      :is-show="showedPopupName === 'ConfirmPopup'"
      :contents-key="popupContentsKey"
      @close="togglePopup"
      @confirm="onConfirmInPopup"
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import CheckSheetItems from '@/view/checkSheet/CheckSheetItems';
import CopyPopup from '@/component/popup/copyPopup/CopyPopup';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import isEmptyObject from '@/asset/js/isEmptyObject';
import NoneEqpDesc from '@/component/noneEqpDesc/NoneEqpDesc';
import LoadingBar from '@/component/loadingBar/loadingBar';
import eqpNameCodeByGroup from '@/asset/js/eqpNameCodeByGroup';

export default {
  name: 'CheckSheetList',
  props: [],
  data() {
    return {
      checkSheet: null,
      checkSheetItems: null,
      isEditMode: false,
      selectedEqpId: null,
      copyingEqp: {},
      showedPopupName: '',
      popupContentsKey: '',
    };
  },
  computed: {
    ...mapState('factoryEquipments', ['selectedEqpByGroup', 'hasEqpByGroup']),
    selectedEqpNameCode() {
      return eqpNameCodeByGroup(this);
    },
  },
  created() {
    this.initRenderData();
  },
  mounted() {},
  watch: {
    $route() {
      this.initRenderData();
    },
  },
  methods: {
    ...mapMutations('factoryEquipments', ['SET_SELECTED_EQP_BY_GROUP']),
    ...mapActions('checkSheets', ['GET_RECENT_CHECK_SHEET', 'POST_CHECK_SHEET_DUPLICATE']),
    isEmptyObject(obj) {
      return isEmptyObject(obj);
    },
    onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'MOVE_TO_COPIED_CHECK_SHEET_PAGE':
          this.moveCopiedCheckSheetPage();
          this.togglePopup();
          break;
        default:
          break;
      }
    },
    moveCopiedCheckSheetPage() {
      this.$router
        .push({
          query: {
            eqp: this.copyingEqp['id'],
            project: this.copyingEqp['manualProjectId'],
          },
        })
        .catch((err) => err);
    },
    initRenderData() {
      this.selectedEqpId = this.$route.query.eqp;

      if (this.selectedEqpId) {
        this.checkSheet = null;
        this.checkSheetItems = null;
        this.getRecentCheckSheet(this.selectedEqpId);
      } else {
        this.SET_SELECTED_EQP_BY_GROUP({});
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    openRegisterPage() {
      this.$router.push(`/check-sheet/register?eqp=${this.selectedEqpId}`).catch((err) => err);
    },
    openEditPage() {
      this.$router.push(`/check-sheet/register/${this.checkSheet.id}?eqp=${this.selectedEqpId}`).catch((err) => err);
    },
    async onCopyCheckSheet(eqp) {
      this.copyingEqp = { ...eqp };
      await this.postCheckSheetDuplicate(this.checkSheet.id, eqp.id);
      this.togglePopup('ConfirmPopup', 'MOVE_TO_COPIED_CHECK_SHEET_PAGE');
    },
    async getRecentCheckSheet(chkGroupId) {
      try {
        const checkSheet = await this.GET_RECENT_CHECK_SHEET(chkGroupId);
        this.checkSheet = checkSheet || {};
        this.checkSheetItems = checkSheet ? checkSheet.items : [];
        this.checkSheetItems.sort(function (a, b) {
          return a.viewOrder - b.viewOrder;
        });
      } catch (error) {
        if (error.response.status === 403) {
          this.checkSheetItems = [];
        }
      }
    },
    async postCheckSheetDuplicate(sheetId, destChkGroupId) {
      await this.POST_CHECK_SHEET_DUPLICATE([sheetId, destChkGroupId]);
    },
  },
  components: { LoadingBar, NoneEqpDesc, ConfirmPopup, CopyPopup, CheckSheetItems, MainSectionHeader },
};
</script>
