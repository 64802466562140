var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section lnb-tree-section" },
    [
      _c(
        "main-section-header",
        {
          attrs: {
            title: "체크시트",
            "is-sticky": true,
            "is-smaller": true,
            "selected-item-name": _vm.selectedEqpNameCode,
          },
        },
        [
          _vm.checkSheet
            ? [
                !_vm.checkSheetItems || _vm.checkSheetItems.length === 0
                  ? _c(
                      "button-basic",
                      {
                        attrs: { slot: "button", color: "primary", size: "s" },
                        on: { click: _vm.openRegisterPage },
                        slot: "button",
                      },
                      [
                        _c("plus-icon", { attrs: { size: 16 } }),
                        _vm._v(" 등록 "),
                      ],
                      1
                    )
                  : [
                      _c(
                        "button-basic",
                        {
                          attrs: {
                            slot: "button",
                            color: "gray-border-1",
                            size: "s",
                            width: 120,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.togglePopup("CopyPopup")
                            },
                          },
                          slot: "button",
                        },
                        [_vm._v(" 체크시트 복제 ")]
                      ),
                      _c(
                        "button-basic",
                        {
                          attrs: {
                            slot: "button",
                            color: "primary",
                            size: "s",
                          },
                          on: { click: _vm.openEditPage },
                          slot: "button",
                        },
                        [_vm._v("수정")]
                      ),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          !_vm.selectedEqpId
            ? _c(
                "div",
                { staticClass: "no-data-description" },
                [
                  _vm.hasEqpByGroup === null
                    ? _c("loading-bar")
                    : _vm.hasEqpByGroup === false
                    ? _c("none-eqp-desc", {
                        attrs: { "item-name": "체크시트" },
                      })
                    : _vm.hasEqpByGroup === true
                    ? _c("p", [
                        _vm._v(
                          "좌측에서 설비를 선택하면, 등록된 체크시트를 확인할 수 있습니다."
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _c("check-sheet-items", {
                attrs: {
                  "is-draggable": false,
                  "is-possible-hover-list": false,
                  "check-sheet-items": _vm.checkSheetItems,
                  "is-edit-mode": false,
                },
              }),
        ],
        1
      ),
      _c("copy-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "CopyPopup",
          title: "check_sheet_copy_title",
          description: "check_sheet_copy_description",
          button: "check_sheet_copy_button",
        },
        on: { close: _vm.togglePopup, confirm: _vm.onCopyCheckSheet },
      }),
      _c("confirm-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "ConfirmPopup",
          "contents-key": _vm.popupContentsKey,
        },
        on: { close: _vm.togglePopup, confirm: _vm.onConfirmInPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }